<template>
  <detail :api="api" :nav="nav" :show-video="true" />
</template>

<script>
import Detail from '@/views/communication/components/detail'
import Api from "@/api/communication/experts"

export default {
  name: 'ExpertsDetail',
  components: {
    Detail
  },
  data() {
    return {
      api: Api.getDetail,
      nav: [
        { name: 'bae89f7', path: '/communication' },
        { name: '6412242', path: '/communication/experts' }
      ]
    }
  },
}
</script>

<style scoped lang="less">
</style>
